const letterValue: { [key: string]: number } = {
  a: 1,
  á: 2,
  b: 3,
  c: 4,
  d: 5,
  ð: 6,
  e: 7,
  é: 8,
  f: 9,
  g: 10,
  h: 11,
  i: 12,
  í: 13,
  j: 14,
  k: 15,
  l: 16,
  m: 17,
  n: 18,
  o: 19,
  ó: 20,
  p: 21,
  q: 22,
  r: 23,
  s: 24,
  t: 25,
  u: 26,
  ú: 27,
  v: 28,
  w: 29,
  x: 30,
  y: 31,
  ý: 32,
  z: 33,
  þ: 34,
  æ: 35,
  ö: 36,
}

export { letterValue }
