import React, { createContext, useContext, useMemo, useCallback } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { format, isValid } from 'date-fns'
import Locale from '@utils/locale'
import { getLocale } from '@utils/helpers'
import { is } from 'date-fns/locale'


export type Translations = Record<Locale, Record<string, string>>

export type TranslateWordFunc = (word: string, { from, to }: { from: Locale; to?: Locale }) => string

const LanguageContext = createContext<{
  locale: Locale
  t: (s: string, defaultIS?: string, defaultEN?: string) => string
  dateFormat: (date: number | Date, formatStr: string) => string
  translateWord: TranslateWordFunc
}>({
  locale: 'is',
  t: s => s,
  dateFormat: (date = new Date(), formatStr = 'PPPP') => format(date, formatStr, { locale: is }),
  translateWord: word => word,
})

export const useTranslations = (): Translations => {
  const data = useStaticQuery(query)
  return useMemo(() => {
    const r: Translations = { is: {}, en: {} }
    for (const node of data.allContentfulTranslation.nodes) {
      const locale: Locale = node.node_locale
      r[locale][node.uid] = node.text
    }
    return r
  }, [data])
}

export const translateBetweenLanguages = (
  translations: Translations,
  word: string,
  { from, to }: { from: Locale; to: Locale },
): string => {
  if (from === to) {
    return word
  }

  const trans = Object.entries(translations[from]).find(([_, text]) => word === text)
  if (!trans) {
    return word
  }

  const [uid] = trans
  return translations[to][uid] || word
}

export const LanguageProvider: React.FC<{ locale: Locale }> = ({ children, locale = 'is' }) => {
  const translations = useTranslations()
  const t = useCallback(
    (uid, defaultIS = '', defaultEN = '') =>
      translations[locale][uid] || translations.is[uid] || (locale === 'is' ? defaultIS : defaultEN) || uid,
    [translations, locale],
  )

  const dateFormat = (date: number | Date, formatStr = 'PPPP') => {
    if (!isValid(date)) {
      return ''
    }

    // Should be displayd in local time
    const dateTime = new Date(date)
    const utcDateTime = dateTime.getTime() + dateTime.getTimezoneOffset() * 60000
    return format(utcDateTime, formatStr, {
      locale: getLocale(locale),
    })
  }

  const translateWord = useCallback<TranslateWordFunc>(
    (word, { from, to = locale }) => translateBetweenLanguages(translations, word, { from, to }),
    [translations, locale],
  )

  return (
    <LanguageContext.Provider value={{ locale, t, dateFormat, translateWord }}>
      {children}
    </LanguageContext.Provider>
  )
}

export const useLanguage = () => useContext(LanguageContext)

export const withLanguageProvider = (Cmp: any, fixedLocale?: Locale) => {
  return (props: any) => {
    return (
      <LanguageProvider locale={fixedLocale || props?.pageContext?.locale}>
        <Cmp {...props} />
      </LanguageProvider>
    )
  }
}

export const query = graphql`
  query GetTranslations {
    allContentfulTranslation {
      nodes {
        uid
        text
        node_locale
      }
    }
  }
`
