import { TixHarpaFragmentFragment } from '@gql-types'
import { isValid, isFuture } from 'date-fns'
import { is, enGB } from 'date-fns/locale'
import moment, { Moment } from 'moment'
import * as fridagar from 'fridagar'
import { groupBy } from 'lodash'
import { letterValue } from './letterValue'
import { AboutFootage, Holiday } from './types'

export const getLanguageObj = (obj: TixHarpaFragmentFragment, lang: string) => {
  const mainTranslation = obj.translations?.find(t => t?.twoLetterCulture === lang) || {}
  const purchaseUrlTranslation = obj.purchaseUrls?.find(t => t?.twoLetterCulture === lang)
  const datesTranslation = obj.dates?.map(date => ({
    ...date,
    ...date?.translations?.find(t => t?.twoLetterCulture === lang),
  }))
  return { ...obj, ...mainTranslation, purchaseUrl: purchaseUrlTranslation, dates: datesTranslation }
}

export const stripHtmlString = (originalString: string): string => {
  if (!originalString) {
    return ''
  }
  return originalString.replace(/(<([^>]+)>)/gi, '').trim()
}

export const capitalizeFirstLetter = (string?: string) =>
  string ? string.charAt(0).toUpperCase() + string.slice(1) : ''

// Math.random should be unique because of its seeding algorithm.
// Convert it to base 36 (numbers + letters), and grab the first 9 characters
// after the decimal.
export const randomId = () => '_' + Math.random().toString(36).substr(2, 9)

export const sortResultsByWeight = (list: any[], query: string, attr: string): any[] => {
  return list.sort(a => {
    if (a[attr].toLowerCase().includes(query)) {
      return -1
    }
    return 1
  })
}

export const sortResultsByFirstDate = (list: any[]): any[] => {
  return list.sort((a, b) => {
    if (a.dates && b.dates) {
      return new Date(a.dates[0].startDate) > new Date(b.dates[0].startDate) ? 1 : -1
    }
    return 0
  })
}

export const getFutureDatesCount = (dates: { startDate: string }[]): number => {
  return dates.reduce((prev, current) => {
    if (isValid(new Date(current.startDate)) && isFuture(new Date(current.startDate))) {
      return (prev += 1)
    } else {
      return prev
    }
  }, 0)
}
export const getLocale = (lang: string) => {
  if (lang === 'en') return enGB
  return is
}

export const isDefined = <T>(x?: T | null): x is T => x != null

export const assertDefined = <T>(x?: T | null): T => {
  if (!isDefined(x)) {
    throw new Error(`Unexpected value ${x}`)
  }
  return x
}

export const footageExists = (footage: AboutFootage | null | undefined) => {
  if (footage?.link || footage?.videoFile?.file?.url || footage?.fallbackImage?.gatsbyImageData) {
    return true
  } else {
    return false
  }
}

export const sortByAlphabet = (list: string[]) => {
  return list.sort((a, b) => {
    const first: number = letterValue[a.substr(0, 1)]
    const second: number = letterValue[b.substr(0, 1)]
    return first - second
  })
}

export const formatNum = (value: number) => {
  return String(value).replace(/(.)(?=(\d{3})+$)/g, '$1.')
}

export const isWeekend = (date: Date) => {
  var dayOfWeek = date.getDay()
  // 6 = Saturday, 0 = Sunday
  return dayOfWeek === 6 || dayOfWeek === 0
}

// get Holidays for next 2 years in case we're
// displaying dec - jan
export const holidayDates = () => {
  const currentYear = new Date().getFullYear()
  const nextYear = currentYear + 1
  const currentYearHolidays = fridagar.getHolidays(currentYear)
  const nextYearHolidays = fridagar.getHolidays(nextYear)
  const relevantDates = [...currentYearHolidays, ...nextYearHolidays]
  return relevantDates.map((holiday: Holiday) => moment(holiday.date))
}

export const getBlockedDays = (day: Moment) => {
  const holidays = holidayDates()

  const dayDate = day.toDate()
  let unAvailableDates: Moment[] = holidays
  const threeMonthsFromNow = moment().add(3, 'months')
  if (isWeekend(dayDate) || day.isSame(moment(), 'day') || day.isAfter(threeMonthsFromNow)) {
    unAvailableDates.push(day)
  }
  return unAvailableDates.some(date => day.isSame(date, 'day'))
}
/* @ts-ignore */
export const normalizeBarChartData = (data: any) => {
  const groupedData = data.reduce((acc, curr) => {
    const { title, data } = curr
    const grouped = groupBy(data, 'label')
    const normalized = Object.keys(grouped).reduce((acc, curr) => {
      const values = grouped[curr].map(item => {
        return {
          x: title,
          y: parseInt(item.value),
        }
      })
      return {
        ...acc,
        [curr]: values,
      }
    }, {})
    const graphKeys = Object.keys(normalized)
    graphKeys.forEach(key => {
      if (acc[key]) {
        acc[key] = [...acc[key], ...normalized[key]]
      } else {
        acc[key] = normalized[key]
      }
    })

    return {
      ...acc,
    }
  }, {})

  const graphKeys = Object.keys(groupedData)
  const iterableData = graphKeys.map(key => {
    return groupedData[key]
  })
  return iterableData
}

export const downloadFile = (filePath: string, fileName: string) => {
  if (!filePath || !fileName) return
  const proxyPath = `.netlify/functions/contentful-proxy?filePath=${filePath}&fileName=${fileName}`
  const origin = window.location.origin
  fetch(`${origin}/${proxyPath}`, {
    method: 'GET',
  })
    .then(response => {
      return response.blob() // Convert stream to blob
    })
    .then(blob => {
      const url = URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.href = url
      link.download = fileName
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    })
    .catch(error => {
    })
}

export const fileToDataURL = (file: File) => {
  return new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (event) => {
      if (event.target) {
        resolve(event.target.result as string);
      } else {
        reject(new Error('Event target is null'));
      }
    };
    reader.onerror = (error) => reject(error);
    reader.readAsDataURL(file);
  });
}

export const dataURLToBlob = (dataURL) => {
  const byteString = atob(dataURL.split(',')[1]);
  const mimeString = dataURL.split(',')[0].split(':')[1].split(';')[0];
  const ab = new ArrayBuffer(byteString.length);
  const ia = new Uint8Array(ab);
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }
  return new Blob([ab], { type: mimeString });
}

export const blobToFile = (blob, fileName) => {
  return new File([blob], fileName, {
      type: blob.type,
      lastModified: Date.now() // or fetch the lastModified from the original File
  });
}


